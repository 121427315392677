@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-i4bv87-MuiSvgIcon-root {
  color: #899aa6;
}

.long-trade {
  border-color: #32df7b;
  color: #32df7b;
}

.short-trade {
  border-color: #eb5757;
  color: #eb5757;
}

.long {
  color: #48b576;
}

.short {
  color: #bb5858;
}

.bg-v2-text-gradient {
  background-image: linear-gradient(247.44deg, #c7f284 13.88%, #00bef0 99.28%);
}

.css-g53se3 {
  color: rgb(255 255 255 / 1);
}

.MuiSlider-markLabel {
  color: #8293a1 !important;
}

/* .chart-container {
  flex: 1;
} */

.tv-lightweight-charts {
  flex: 1;
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #10181e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #02080c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #061722;
}
